import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./template.scss";

export default function StaticSites() {
  return (
    <>
      <Navbar />
      <div className="project-container">
        <div className="header project-padding">
          <h1 className="page-heading">
            <span>Random Sites</span>
            <span className="dot" style={{ color: "#FFCBE5" }}>
              .
            </span>
          </h1>
        </div>
        <div className="project-post project-padding">
          <div
            className="project-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

const html = `
  <p class="has-line-data" data-line-start="0" data-line-end="1">Since 2015, I have been making and designing websites for various reasons. Some of these have seen better days, and some are still wonderful. This page serves as a compilation of some of my favorite ones throughout the years. All of them were excellent opportunities to learn something new that I hadn’t done before. Here are a few I would like to briefly highlight:</p>
  <h2 class="code-line" data-line-start=2 data-line-end=3 ><a id="Tj_Morrow_2"></a>Tj Morrow</h2>
  <p class="has-line-data-img" data-line-start="3" data-line-end="4"><a href="https://tjmorrowmusic.com" target="_blank" rel="norefferer"><img src="/tjmorrow.jpg" alt="Tj Morrow preview"></a></p>
  <h3 class="code-line" data-line-start=5 data-line-end=6 ><a id="2019___React_Styled_Components_5"></a>2019  |  React, Styled Components</h3>
  <p class="has-line-data" data-line-start="7" data-line-end="8"><a href="https://tjmorrowmusic.com" target="_blank" rel="norefferer">Tj Morrow</a> came to me towards the end of 2019 with a need for an updated website. I had recently been playing around with React and so I took this as an opportunity to try my hand at using Styled Components. The website turned out really nice and I think it looks pretty good almost 3 years later. I have updated it a few times to include Tj’s latest music as he puts out more music. This sounds like a good opportunity to incorporate a CMS so he can update things as he sees fit. I would also like to incorporate a light mode to this site as well since it is a pretty dark website.</p>
  <p class="has-line-data" data-line-start="9" data-line-end="10">I am not sure if Styled Components are my thing. However, I can see a use case for them, and building this website definitely will make me feel more comfortable in the future if there is ever a need to use them again.</p>
  <h2 class="code-line" data-line-start=11 data-line-end=12 ><a id="Drip_Coffee_11"></a>Drip Coffee</h2>
  <p class="has-line-data-img" data-line-start="3" data-line-end="4"><a href="https://dribbble.com/shots/14389001-Landing-Page-Coffee-Service" target="_blank" rel="norefferer"><img src="/dripcoffee.jpg" alt="Drip Coffee preview"></a></p>
  <h3 class="code-line" data-line-start=13 data-line-end=14 ><a id="2019__Adobe_XD_13"></a>2019 | Adobe XD</h3>
  <p class="has-line-data" data-line-start="15" data-line-end="16">Drip Coffee is a simple landing page design, and was one of my first ventures into using design software. For this design, I chose to use Adobe XD and I still use it quite frequently today. I have since dabbled with other design software such as Figma and Sketch but I still primarily stick with XD. They all seem pretty similar and I think having a foundation in any of these programs can translate well.</p>
  <h2 class="code-line" data-line-start=17 data-line-end=18 ><a id="Jason_McNabb_17"></a>Jason McNabb</h2>
  <p class="has-line-data-img" data-line-start="3" data-line-end="4"><a href="https://jasonmcnabbmusic.com" target="_blank" rel="norefferer"><img src="/jasonmcnabb.jpg" alt="Jason McNabb preview"></a></p>
  <h3 class="code-line" data-line-start=19 data-line-end=20 ><a id="2019__Nodejs_JavaScript_19"></a>2019 | Node.js, JavaScript</h3>
  <p class="has-line-data" data-line-start="21" data-line-end="22">I’m not sure I am still crazy about this design, it definitely seems dated. Unfortunately, I think most musician websites end up looking quite out of touch. <a href="https://jasonmcnabbmusic.com" target="_blank" rel="norefferer">Jason McNabb</a> is a musician from Canada with music across all streaming platforms and when I first designed the website using WiX in 2017, he was just coming out with his debut solo album, Brand New Me. In 2019 I helped Jason migrate his website from the WiX platform to save money and for more creative freedom.</p>
  <p class="has-line-data" data-line-start="23" data-line-end="24">In order to host this site on Netlify, I wrote the new site using Node.js and Embedded JavaScript. I used this as an opportunity to code an exact clone of the WiX site using just HTML, CSS, and EJS. We have yet to get around to a redesign of this site but hopefully someday soon!</p>
  <h2 class="code-line" data-line-start=25 data-line-end=26 ><a id="Random_Nature_Site_25"></a>Bootstrap Site</h2>
  <p class="has-line-data-img" data-line-start="3" data-line-end="4"><a href="https://nature.beauherrondev.com" target="_blank" rel="norefferer"><img src="/naturesite.jpg" alt="Nature Site preview"></a></p>
  <h3 class="code-line" data-line-start=27 data-line-end=28 ><a id="2018__HTML_CSS_Bootstrap_4_27"></a>2018 | HTML, CSS, Bootstrap 4</h3>
  <p class="has-line-data" data-line-start="29" data-line-end="30"><a href="https://nature.beauherrondev.com" target="_blank" rel="norefferer">Nature Site</a> was the result of a tutorial that I followed in order to learn Bootstrap 4. There’s not much to say about this site besides that it was a great introduction to Bootstrap and CSS frameworks in general. The actual site is just a mock website with no real purpose but looking back at it, it still looks pretty good. It just goes to show how useful a solid CSS framework can be and how well it can</p>
  <h2 class="code-line" data-line-start=31 data-line-end=32 ><a id="MurrayMac_Records_31"></a>MurrayMac Records</h2>
  <p class="has-line-data-img" data-line-start="3" data-line-end="4"><a href="https://murraymacrecords.com" target="_blank" rel="norefferer"><img src="/murraymac.jpg" alt="MurrayMac Records preview"></a></p>
  <h3 class="code-line" data-line-start=33 data-line-end=34 ><a id="2018__HTML_CSS_PHP_33"></a>2018 | HTML, CSS, PHP</h3>
  <p class="has-line-data" data-line-start="35" data-line-end="36">This was the first site that I coded using strictly HTML and CSS and was done for a client - <a href="https://murraymacrecords.com" target="_blank" rel="norefferer">MurrayMac Records</a> - that needed a new record label/production company website. I remember deciding on the minimalist design and falling in love with the idea. I think the website still holds up today. I would definitely change things, but overall, I think this was a timeless design.</p>
  <p class="has-line-data" data-line-start="37" data-line-end="38">This was also a great learning experience with web hosting. I can’t even remember the web hosting service that I used but it was a giant pain to get this thing hosted. I spent a lot of time using FileZilla to transfer files to my hosting service. Eventually, I discovered <a href="https://netlify.com" target="_blank" rel="norefferer">Netlify</a> and haven’t looked back.</p>
  <p class="has-line-data" data-line-start="39" data-line-end="40">Prior to migrating this site to Netlify, I had written a custom PHP form handler. I spent a lot of my time updating this form handler to try and avoid bot responses. Luckily the form is now simply handled using Netlify Forms.</p>
`;
